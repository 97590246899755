import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { AppConstants } from "../Constants/AppConstants";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log('checking localstorage')
    const storedUserID = localStorage.getItem("userID");
    const storedAppID = localStorage.getItem("appID");
    if (storedUserID && storedAppID) {
      console.log('localstorage available')
      navigate("/amr", { state: { appID: storedAppID, userID : storedUserID} });
    }
  }, [navigate]);

  const handleLogin = async () => {
    try {
      setLoading(true);
      let datavalue = {
        id: email,
        password: password,
      };
      const urlBuilder = `${AppConstants.BASE_URL}/api/login`;
      var loginApi = await fetch(urlBuilder, {
        method: "post",
        headers: {
          Accept: AppConstants.Application,
          "Content-Type": AppConstants.Contant,
        },
        body: JSON.stringify(datavalue),
      });
      if(loginApi.status == 200){
        var res =  await responseBuilder(loginApi);
        let appID = res.response.body.appId;
        let userID = res.response.body.userId
        localStorage.clear();
        localStorage.setItem("userID", userID);
        localStorage.setItem("appID", appID);
        console.log('localStorage', localStorage)
        setLoading(false);
        navigate("/amr", { state: { appID: appID, userID : userID} })
      } else {
        if(loginApi.status != 200){
          var res = await responseBuilder(loginApi);
          toast(res.results[0]);
          setLoading(false);
          return res
        }
      }
    } catch (error) {
      console.error("error in login", error);
      toast(error);
    }
  };

  const responseBuilder = async data => {
    try {
      const json = await data.json();
      return json;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    //<div >
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "#344675", height: `100vh` }}>
      {loading ? (
        <div>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
            visible={true}
          />
        </div>
      ) : (
        <Card sx={{ maxWidth: 350 }}>
          <CardContent>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                component="h1"
                variant="body2"
                style={{ textAlign: `center` }}>
                Please sign in to your account below.
              </Typography>
            </Grid>
            <form>
              <Grid container spacing={2} style={{ padding: `10px` }}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    required
                    id="userId"
                    label="UserId"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    required
                    type="password"
                    name="password"
                    id="examplePassword"
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleLogin()}>
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}

      <ToastContainer />
    </Grid>
    //</div>
  );
}
