import React from 'react';
import { Navigate } from "react-router-dom";

const sessionDetails = () => {
  console.log('localStorage route', localStorage.getItem(`userID`))
    return localStorage.getItem(`userID`);
  };
  
  const PrivateRoute = ({ children }) => {
    const tokenData = sessionDetails();
  
    return tokenData ? children : <Navigate to="/" />;
  };
  
  export default PrivateRoute;
