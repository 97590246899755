import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import ScreenSelection from "./Screens/ScreenSelection";
import SignIn from "./Screens/SignIn";
import PrivateRoute from "./PrivateRoute";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route
            path="/home"
            element={
              <PrivateRoute>
                <HomeScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/amr"
            element={
              <PrivateRoute>
                <ScreenSelection />
              </PrivateRoute>
            }
          />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
