import React, { memo } from "react";
import SockJsClient from "react-stomp";
import { AppConstants } from "../Constants/AppConstants";

const MapWebSocket = ({ statusCheck, messageLog }) => {
  // const [topics, setTopics] = React.useState([]);

  const onConnected = () => {
    statusCheck(true);
    console.log("Connected!!");
    // setTopics(["/amr/websocket/logs"]);
  };

  const onMessageReceived = (data) => {
    console.log(data);
    messageLog(data);
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (topics.length > 0) {
  //       console.log("entred");
  //       statusCheck(true);
  //     } else {
  //       console.log("entred");
  //       statusCheck(false);
  //     }
  //   }, 3000);
  // }, []);

  return (
    <SockJsClient
      url={`${AppConstants.BASE_URL}/amr-ws`}
      topics={["/amr/websocket/logs"]}
      onConnect={onConnected}
      onMessage={(data) => onMessageReceived(data)}
      debug={false}
    />
  );
};

export default memo(MapWebSocket);
