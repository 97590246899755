import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import AlaiLogo from "../../src/images/alailogo.png";
import Green from "../../src/images/green.png";
import Red from "../../src/images/red.png";
import MapWebSocket from "../WebSockets/feedbackWebSocket";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { AppConstants } from "../Constants/AppConstants";
import Backdrop from "../Constants/Loading";
const HomeScreen = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const [status, setstatus] = useState("");
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [backdropVisible, setBackdropVisible] = useState(false);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const appID = location.state.appID;
  const userID = location.state.userID;

  const StartAMR = async () => {
    try {
      setBackdropVisible(true);
      TimeSet();
      const urlBuilder = `${AppConstants.BASE_URL}/api/start?id=AMR${location.state.machineID}`;
      await fetch(urlBuilder, {
        method: "get",
        headers: {
          Accept: AppConstants.Application,
          "Content-Type": AppConstants.Contant,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const StartLoading = async () => {
    try {
      setBackdropVisible(true);
      TimeSet();
      const urlBuilder = `${AppConstants.BASE_URL}/api/load?id=AMR${location.state.machineID}`;
      await fetch(urlBuilder, {
        method: "get",
        headers: {
          Accept: AppConstants.Application,
          "Content-Type": AppConstants.Contant,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const SettingStatus = useCallback((e) => {
    console.log(e);
    if (e) {
      setstatus(AppConstants.Online);
    } else if (!e) {
      setstatus(AppConstants.Offline);
    }
  }, []);

  useEffect(() => {
    if (status === AppConstants.Online) {
      toast.success("Device Conneced");
    } else {
      if (status === AppConstants.Offline) {
        toast.error("Have an trouble to connect with Device");
      }
    }
  }, [status]);

  const MessageLog = useCallback((e) => {
    if (typeof e === "string") {
      let dummy = JSON.parse(e);
      if (dummy?.status === "good") {
        toast.success("Command Initiated Successfully");
      } else if (dummy?.status !== "good") {
        toast.error("Command Execution Failed");
      }
    }
  }, []);

  const TimeSet = async () => {
    const timer = setTimeout(() => {
      setBackdropVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100wh",
          maxHeight: "100vh",
          height: "100vh",
          background: "#2978C4",
        }}>
        {backdropVisible && <Backdrop open={true} />}
        <MapWebSocket
          statusCheck={(e) => SettingStatus(e)}
          messageLog={(e) => MessageLog(e)}
        />
        <ToastContainer />
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            gap={1}
            sx={{
              height: "12%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100% ",
            }}>
            <Grid
              item
              sm={8}
              xs={8}
              md={8}
              sx={{
                background: "#F7D7D8",
                display: "flex",
                alignItems: "center",
                height: "100%",
                borderRadius: "10px",
                marginLeft: "10px",
                justifyContent: "center",
              }}>
              <Button onClick={() => navigate("/amr" , { state: { appID : appID, userID : userID } })}>
                <ArrowCircleLeftRoundedIcon
                  sx={{ fontSize: screenSize.width / 30 }}
                />
              </Button>
              <Typography
                sx={{
                  marginLeft: "5%",
                  fontSize: screenSize.width / 45,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}>
                SMART AMR TASK CONTROLLER{" "}
                {status.length > 0 ? `(${status}) ` : null}
                {status.length > 0 && (
                  <img
                    src={status === AppConstants.Online ? Green : Red}
                    width={screenSize.width / 40}
                    height={screenSize.width / 40}
                    style={{ marginLeft: `5px` }}
                    alt="Alai tech"
                  />
                )}
              </Typography>
            </Grid>
            {/*   <Grid
              item
              sm={4}
              xs={4}
              md={4}
              sx={{
                background: "#F7D7D8",
                display: "flex",
                height: "100%",
                borderRadius: "10px",
                marginRight: "10px",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}>
              <div>
                  <Typography
                  sx={{
                    marginLeft: "5%",
                    fontSize: screenSize.width / 50,
                    fontWeight: "bold",
                    color: "#858084",
                  }}>
                  AMR Status {status}
                </Typography> 
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      fontSize: screenSize.width / 55,
                      fontWeight: "bold",
                      color: "#000000",
                    }}>
                    AMR Status
                  </Typography>
                  <Typography
                    sx={{
                      marginLeft: `5px`,
                      fontSize: screenSize.width / 55,
                      fontWeight: "bold",
                      color: "green",
                    }}>
                    {`(${status}) `}
                  </Typography>
                  <img
                    src={Green}
                    width={screenSize.width / 40}
                    height={screenSize.width / 40}
                    style={{ marginLeft: `5px` }}
                  />
                </div>
              </div>
            </Grid> */}
          </Grid>
          {/*Right comp*/}
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            gap={1}
            sx={{
              height: "82%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid
              item
              sm={8}
              xs={8}
              md={8}
              sx={{
                background: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                height: "100%",
                borderRadius: "10px",
                marginLeft: "10px",
                width: "100%",
                flexDirection: "column",
              }}>
              <div
                style={{
                  flex: 0.2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <img
                  src={AlaiLogo}
                  width={screenSize.width / 5}
                  height={screenSize.width / 18}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection:
                    screenSize.width < screenSize.height ? "column" : "row",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flex: 0.75,
                  alignItems: "center",
                }}>
                <Button
                  variant="contained"
                  disabled={status !== "Online" ? true : false}
                  sx={{
                    height: screenSize.height / 8,
                    width: screenSize.width < screenSize.height ? "50%" : "30%",
                    fontSize:
                    screenSize.width < screenSize.height
                      ? screenSize.width / 50
                      : screenSize.width / 60,
                      background:"green",
                      color:"#FFFFFF",
                      '&:hover': {
                        backgroundColor: 'green',
                        color: '#FFFFFF',
                    },
                  }}
                  onClick={() => StartAMR()}>
                  Start AMR
                </Button>
                <Button
                  variant="contained"
                  disabled={status !== "Online" ? true : false}
                  sx={{
                    height: screenSize.height / 8,
                    width: screenSize.width < screenSize.height ? "50%" : "30%",
                    fontSize:
                      screenSize.width < screenSize.height
                        ? screenSize.width / 50
                        : screenSize.width / 60,
                        background:"yellow",
                        color:"#000000",
                        '&:hover': {
                          backgroundColor: 'yellow',
                          color: '#000000',
                      },
                  }}
                  onClick={() => StartLoading()}>
                  Start Loading
                </Button>
              </div>
              <Typography
                sx={{
                  color: "#000000",
                  flex: 0.05,
                  fontSize: screenSize.width / 80,
                }}>
                {`Copyright @ ${new Date().getFullYear()} Alai technology labs All rights reserved`}
              </Typography>
            </Grid>
            {/*  <Grid
              item
              sm={4}
              xs={4}
              md={4}
              sx={{
                background: "#FFFFFF",
                display: "flex",
                height: "100%",
                borderRadius: "10px",
                marginRight: "10px",
                flexDirection: "column",
              }}>
              <Typography
                sx={{
                  background: "#BBBBBB",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}>
                &nbsp; Real Time Log
              </Typography>
              <List style={{ overflow: "auto" }}>
                {itemsList.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText>
                      <Typography
                        key={index}
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary">
                        {item}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default HomeScreen;
