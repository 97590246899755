import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import AlaiLogo from "../../src/images/alailogo.png";
import "react-toastify/dist/ReactToastify.css";
import {useLocation, useNavigate } from "react-router-dom";
import { AppConstants } from "../Constants/AppConstants";
import LogoutIcon from '@mui/icons-material/Logout';

const ScreenSelection = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [isAmrOne, setIsAmrOne] = useState(false);
  const [isAmrTwo, setIsAmrTwo] = useState(false);
  const [loading, setLoading] = React.useState(false);
   
  useEffect(() => {

    if(location.state.appID == AppConstants.AMROne){
      setIsAmrOne(true);
    } else {
      if(location.state.appID == AppConstants.AMRTwo){
        setIsAmrTwo(true);
      }
    }

    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleLogout = async () => {
    try {
      setLoading(true);
      console.log('sjsjs..',location.state)
      const urlBuilder = `${AppConstants.BASE_URL}/api/logout?id=${location.state.userID}`;
      var logOutApi = await fetch(urlBuilder, {
        method: "get",
        headers: {
          Accept: AppConstants.Application,
          "Content-Type": AppConstants.Contant,
        },
      });
      if(logOutApi.status == 200){
        var res =  await responseBuilder(logOutApi);
        localStorage.removeItem("userID");
        setLoading(false);
        navigate("/")
      } else {
        if(logOutApi.status != 200){
          var res = await responseBuilder(logOutApi);
          console.log(res);
          setLoading(false);
          return res
        }
      }
    } catch (error) {
      console.error("error in login", error);
    }
  };

  const responseBuilder = async data => {
    try {
      const json = await data.json();
      return json;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100wh",
          maxHeight: "100vh",
          height: "100vh",
          background: "#2978C4",
        }}>
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            gap={1}
            sx={{
              height: "12%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100% ",
            }}>
            <Grid
              item
              sm={8}
              xs={8}
              md={8}
              sx={{
                background: "#F7D7D8",
                display: "flex",
                alignItems: "center",
                height: "100%",
                borderRadius: "10px",
                marginLeft: "10px",
                justifyContent: "center",
              }}>
              <Typography
                sx={{
                  marginRight: "5%",
                  fontSize: screenSize.width / 45,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}>
                SMART AMR TASK CONTROLLER
              </Typography>
              <Button onClick={() => handleLogout()}>
                <LogoutIcon
                  sx={{ fontSize: screenSize.width / 30 }}
                />
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            gap={1}
            sx={{
              height: "82%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid
              item
              sm={8}
              xs={8}
              md={8}
              sx={{
                background: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                height: "100%",
                borderRadius: "10px",
                marginLeft: "10px",
                width: "100%",
                flexDirection: "column",
              }}>
              <div
                style={{
                  flex: 0.2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <img
                  src={AlaiLogo}
                  width={screenSize.width / 5}
                  height={screenSize.width / 18}
                  alt="ALAI Technology"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    screenSize.width < screenSize.height ? "column" : "row",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flex: 0.75,
                  alignItems: "center",
                }}>
                <Button
                  variant="contained"
                  disabled={!isAmrOne}
                  sx={{
                    height: screenSize.height / 8,
                    width: screenSize.width < screenSize.height ? "50%" : "30%",
                    fontSize:
                      screenSize.width < screenSize.height
                        ? screenSize.width / 50
                        : screenSize.width / 60,
                  }}
                  onClick={() =>
                    navigate("/home", { state: { machineID: 1, appID : location.state.appID, userID : location.state.userID } })
                  }>
                  AMR 1
                </Button>
                <Button
                  variant="contained"
                  disabled={!isAmrTwo}
                  sx={{
                    height: screenSize.height / 8,
                    width: screenSize.width < screenSize.height ? "50%" : "30%",
                    fontSize:
                      screenSize.width < screenSize.height
                        ? screenSize.width / 50
                        : screenSize.width / 60,
                  }}
                  onClick={() =>
                    navigate("/home", { state: { machineID: 2, appID : location.state.appID, userID : location.state.userID  } })
                  }>
                  AMR 2
                </Button>
              </div>
              <Typography
                sx={{
                  color: "#000000",
                  flex: 0.05,
                  fontSize: screenSize.width / 80,
                }}>
                {`Copyright @ ${new Date().getFullYear()} Alai technology labs All rights reserved`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ScreenSelection;
